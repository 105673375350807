/*
All handheld devices: mobile and tablets css
*/

#ground.ground2 {
	background-color: white;
	background-image: none;
}

#mobile_header {

	.row {
		margin: 0;
	}

}

#realcme_middle_content .container {
	padding-left: 10px;
	padding-right: 10px;

	.course-filter-menu {

		.sort-drop {
			padding: 0;
		}

	}
}

#closure-blocks,
#copyright,
#bottom_links {
	display: none;
}

.container-fluid form {
	border-radius: 0;
	margin-bottom: 0;
	padding-bottom: 40px;
}


/* ======== FRONT MATTER ======== */

#realcme_middle_content {

	.frontmatter-image {
		margin: 0 10px 0 0;
	}

	.fm-course-title {
		margin: 0 0 0 55px
	}

	.col-md-8,
	.col-md-4,
	.col-md-12.ccc-border-top {
		padding: 0;
	}

	.primary-relevant-container,
	.faculty-bio-row .fm-page,
	.course-information {
		padding: 5px;
	}

	.ccc-border-top .fm-start-course {
		padding: 0 0 15px 0;
	}

	#course {
		padding-bottom: 60px
	}

}

#learning-objective {

	.visible-xs tr {
		padding: 5px;
	}

}


/* ======== PLAYER ======== */

.client-player .course-pages {
	padding-left: 10px;
	padding-right: 10px;

	.certification {
		.certification-results .header {
			h2 {
				font-size: 26px;
			}
		}
	}

	.instruction-box {

		p {
			.form-group {
				label {
					display: block;
				}
				input {
					float: left;
					margin: 5px 5px 0 0;
				}
			}
			.num_moves_box {
				margin-left: 10px;
			}
		}

	}

	.certification-form .certification-form-address,
	.certification-form .certification-form-address .form-group,
	.certification-form .certification-form-credit-info,
	.certification-form .certification-form-credit-info .form-group,
	.eval-questions .question-answers .row .col-md-12,
	.likert-answers .sub-question-answers,
	.multiple-choice-review .col-xs-12.col-sm-6,
	.pre-post-review .col-xs-12.col-sm-6,
	.question-answers .choice-answer,
	.question-answers .question-answer,
	.question-heading .col-md-12,
	.question-index,
	.review-commentary,
	.suceess-details .success-text,
	.suceess-details .user-buttons {
		padding-left: 0;
		padding-right: 0;
	}

	.suceess-details .user-buttons .btn-medium-value {
		width: 240px;
	}

	.multi-dimensional .question-titles h3 {
		font-size: 15px;
	}

	.question-heading,
	.question-text {
		padding: 0;
	}

	.question-text .instruction-box {
		margin-left: 0;
		margin-right: 0;
	}

	.references {
		padding-left: 0;
	}

	.review-commentary {

		.commentary {
			padding-left: 0;
			padding-right: 0;
		}
		.likert-table p.likert-value a {
			padding: 10px 16px;
		}

	}

	.review .question-answer .response-icon-wrapper {
		left: -11px;
	}

}

.client-player {
	.main-nav {
		.page-count .menu-toggle {
			background-position: -30px;
		}
	}
}

.footer-nav {

	.footer-left {
		margin: 0 0 0 10px;
	}
	.footer-right {
		margin-right: 10px;
	}

}

/* ======== CME COMPANION PAGES ======== */

#realcme_middle_content {

	.courses__panel-listing .course-curricula-container {
		padding-left: 0;
		padding-right: 0;
	}

	.planner-page {
		.planner__reminder {
			padding-left: 0;
			padding-right: 0;
			display: inline-block;
		}
	}

	.profile-page {

		#accordion2 {
			#profile_information {
				.col-md-12 {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
		#accordion1,
		#accordion2,
		#accordion3 {
			padding-left: 0;
			padding-right: 0;
		}
		#login_settings .profile-button-container,
		#profile_information .profile-button-container,
		#profile_settings .profile-button-container {
			text-align: center;
		}

		#profile_settings {

			.profile-page {
				padding-left: 0;
				padding-right: 0;
			}
			.profile-timezone {
				padding-top: 30px;
			}

			form {
				padding-bottom: 50px;
			}

		}

	}

	.subject-mastery-wrapper,
	.subject-goal-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	#archive_1 .bb-grey {
		padding-left: 0;
		padding-right: 0;
	}

	#new_goal form {
		padding-bottom: 60px;

		.input-group {
			margin-bottom: 15px;
		}
	}

}

/* ======== ERROR PAGES ======== */

.full_width .course-filtering-options {
	display: inline-block;
    float: none;
}

